::-webkit-scrollbar {
  width:9px;
  height:9px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:#101021;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:#303061;
}

::-webkit-scrollbar-thumb:hover {
  background:#303061;
}

::-webkit-scrollbar-thumb:window-inactive {
  background:#303061;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #202031 inset !important;
}

.Doc-body {
  background-color: #101021;
  font-family: Roboto;
  color: rgba(255, 255, 255, 0.7);
}

.App {
  height: 100%;
  overflow: hidden;
}

.App-header {
  font-size: 12px;
}

.App-header-left {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 0.8rem;
}

.App-title {
  font-size: 34px;
}

.App-title-mobile {
  font-size: 18px;
}

.App-icon {
  color: rgba(255, 255, 255, 0.7);
}

.App-add-button {
  margin-right: 8px !important;
  margin-top: 15px !important;
}

.App-bottom {
  bottom: 0px;
  left: 0px !important;
  right: 0px !important;
  position: fixed;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  width: auto !important;
  background-color: #101021 !important;
}

.App-bottom-mobile {
  bottom: 0px;
  left: 0px !important;
  right: 0px !important;
  position: fixed;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  width: auto !important;
  background-color: #101021 !important;
  border-top: 1px rgba(200, 200, 255, 0.42) solid !important;
}

.App-about-mobile {
  margin: 5px 54px;
}

.App-about {
  margin: 25px 54px;
}

.App-hidden {
  visibility: hidden;
}

.App-taskText span {
  font-size: 0.9rem;
  color: white;
}

.App-editTaskName {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  min-width: 100% !important;
}

.App-editTaskName:active {
  background-color: none;
}

.App-editTaskDesc {
  min-width: 100% !important;
}

.App-editTaskName div {
  padding: 0px 4px;
}

.makeStyles-modal-13 div[aria-hidden] {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.App-dateTimePicker {
  padding-top: 0px !important;
}

.App-dateTimePicker div {
  min-width: 100%;
}

.App-project-header {
  flex-wrap: 'nowrap';
  margin-top: 15px;
  padding: 0px;
}

.App-project-header:hover {
  flex-wrap: 'nowrap';
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
}